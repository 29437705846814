import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { PresentationComponent } from './presentation/presentation.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { GodigitalComponent } from './godigital.component';

const routes: Routes = [
  {
    path: '',
    component: GodigitalComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'home/services/:id',
        component: ServicesComponent
      },
      {
        path: 'about',
        component: AboutComponent
      },
      {
        path: 'contact',
        component: ContactComponent
      },
      {
        path: 'presentation',
        component: PresentationComponent
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent
      },  
    ]
  },
  {
    path: 'godigital',
    redirectTo: 'godigital/home',
    pathMatch: 'full',
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GoDigitalRoutingModule {}
