import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    private _scrollToService: ScrollToService,
    private route: ActivatedRoute
    ) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe((params: any) => {
      console.log(params.params);
      const param = params.params;
      if (param.page !== '') {
        const config: ScrollToConfigOptions = {
          target: param.page
        };
        this._scrollToService.scrollTo(config);
      }
    });
  }
}
