import { Component, OnInit } from '@angular/core';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { PDFProgressData, PDFDocumentProxy } from 'pdfjs-dist';

@Component({
  selector: 'app-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.css']
})
export class PresentationComponent implements OnInit {
  pdfSrc: string = './../assets/pdf/presentacion.pdf';
  constructor(
    private _scrollToService: ScrollToService
  ) { }

  ngOnInit() {
    const config: ScrollToConfigOptions = {
      target: 'presentation'
    };
    this._scrollToService.scrollTo(config);
  }
  onProgress(progressData: PDFProgressData){
    console.log('onProgress');
    console.log(event);
  }
  callBackFn(pdf: PDFDocumentProxy) {
    console.log('callBackFn');
    console.log(pdf);
 }
 pageRendered(e: CustomEvent) {
   console.log('pageRendered');
    console.log('(page-rendered)', e);
  }
}
