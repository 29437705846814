import { NgModule } from '@angular/core';
import { UnoRoutingModule } from './uno-routing.module';
import { UnoComponent } from './uno.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { PrizeComponent } from './prize/prize.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    UnoComponent,
    PrizeComponent
  ],
  imports: [
    SharedModule,
    UnoRoutingModule,
    NgbModalModule
  ],
  entryComponents: [
    PrizeComponent
  ]
})
export class UnoModule { }
