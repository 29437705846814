import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  isHome = true;
  constructor(
    private router: Router
  ) {
    this.router.events.subscribe((val: any) => {
      if (val.url !== undefined) {
        this.isHome = (val.url !== '/') ? false : true;
      }
    });
   }

  ngOnInit() {
    $(document).ready(function() {
      $('.menu-icon').on('click', function() {
        $('nav ul').toggleClass('showing');
      });
      $('nav div ul li a').on('click', function() {
        $('nav ul').toggleClass('showing');
      });
    });
    $(window).on('scroll', function() {
      if ($(window).scrollTop()) {
        $('nav').addClass('black');
      } else {
        $('nav').removeClass('black');
      }
    });
  }

}
