import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-prize',
  templateUrl: './prize.component.html',
  styleUrls: ['./prize.component.css']
})
export class PrizeComponent implements OnInit {

  @Input()
  data: any = [];
  max: number = 10;
  messages: any[] = [
    {
      value: "Has acumulado 4 acciones para 2 meses de gasolina super",
      showQr: false
    },
    {
      value: "Te has ganado una recarga de C$300",
      showQr: true
    },
    {
      value: "Estas participando en la rifa de un Hyundai E30",
      showQr: true
    },
    {
      value: "Te has ganado 5 acciones para la rifa de un año de combustible gratis",
      showQr: false
    },
    {
      value: "Has ganado C$200 en productos de PRONTO",
      showQr: true
    },
    {
      value: "Estas participando en la rifa de un año gratis de diesel",
      showQr: true
    },
    {
      value: "Has acumulado 10 acciones para 2 meses de gasolina super",
      showQr: false
    },
    {
      value: "Gratias por participar",
      showQr: false
    }
  ];
  selectedMessage: any = null;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit() {
    let count = 0;
    while(count <= this.max) {
      this.shoot();
      count++;
    }
    this.selectedMessage = this.messages[Math.round(this.random(0, this.messages.length) - 1)];
  }

  shoot() {
    try {
      this.confetti({
        angle: this.random(60, 120),
        spread: this.random(10, 50),
        particleCount: this.random(40, 50),
        origin: {
            y: 0.6
        }
      });
    } catch(e) {
      // noop, confettijs may not be loaded yet
    }
  }

  confetti(args: any) {
    let _ = window['confetti'].apply(this, arguments);
    return _;
  }

  random(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

}
