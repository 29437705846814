import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const SHAREDMODULES: any[] = [
  FormsModule,
  ReactiveFormsModule
];

const SHAREDCOMPONENTS: any[] = [

];

const ENTRYCOMPONENTS: any[] = [

];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SHAREDMODULES,
  ],
  exports: [
    CommonModule,
    SHAREDMODULES,
    RouterModule,
    SHAREDCOMPONENTS,
  ],
  entryComponents: [ENTRYCOMPONENTS],
})
export class SharedModule { }
