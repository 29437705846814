import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from './../global'; 

@Injectable({
  providedIn: 'root'
})
export class HttpServiceService {

  urlApi = myGlobals.urlApi;

  constructor(private http: HttpClient) { }

  get(url) {
    return this.http.get(`${this.urlApi}${url}?token=${ myGlobals.token }`);
  }
  post(url, body) {
    return this.http.post(`${this.urlApi}${url}?token=${ myGlobals.token }`, body);
  }
  put(url, body) {
    return this.http.put(`${this.urlApi}${url}?token=${ myGlobals.token }`, body);
  }
  delete(url) {
    return this.http.delete(`${this.urlApi}${url}?token=${ myGlobals.token }`);
  }
}
