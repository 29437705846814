import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UnoComponent } from './uno/uno.component';

const routes: Routes = [
  {
    path: 'godigital',
    loadChildren: './godigital/godigital.module#GodigitalModule'
  },
  {
    path: 'uno',
    loadChildren: './uno/uno.module#UnoModule'
  },
  {
    path: '',
    redirectTo: 'godigital/home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
