import { Component, OnInit } from '@angular/core';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {
  id: number;
  filteredServices: any;
  services: any = [
    {
      id: 1,
      title: 'Programas de Lealtad ',
      imgUrl: './../../assets/img/loyalty.png',
      h1: 'GoLoyalty',
      /*h2: `¿Cuántos clientes has ganado o perdido en los últimos años?
      ¿Cómo ha cambiado el consumo de ellos?
      ¿Qué productos y servicios adicionales podes ofrecerles basado en sus intereses y comportamiento?`,*/
      p: [
        `Los programas de Lealtad son la base para una realación exitosa con tus clientes y para el crecimiento.`,
        `Nuestra plataforma para crear tu programa de lealtad, totalmente digital, no requiere de formatos físicos 
        ni tarjetas físicas. Dinámico, fácilmente ajustable a tu negocio y divertido para el cliente.`,
        `Click para más información`
      ]
    },
    {
      id: 2,
      title: 'Ecommerce Shopify',
      imgUrl: './../../assets/img/card2.png',
      h1: 'Ecommerce con Shopify - Official partners',
      h2: 'Vende tus productos en línea, multiplicando automáticamente tu mercado potencial al cubrir al mercado local e internacional.',
      p: [
        `Nosotros hacemos fácil, lo difícil.
        Nos encargamos de todo lo técnico para que vos te concentres en lo más importante, tu negocio.`,
        `Somos partner autorizado de Shopify, la plataforma más grande de Ecommerce en el mundo.
        Ya sea que vendas en una página web, en Facebook o desde el maletero de tu carro,
        Shopify tiene una gran plataforma de Ecommerce para que empieces a vender en unas cuantas horas.`,
        `El Ecommerce en nuestra región tiene un potencial enorme,
        no debería existir en el mundo real una tienda que no exista en el mundo digital,
        pero esta máxima no funciona a la inversa, muchas tiendas no existen físicamente,
        pero sí digitalmente. Esto explica la importancia y el potencial de negocio del Ecommerce en nuestra región.`,
        `Consultanos sobre tus inquietudes e inicia tu negocio en el mundo de Ecommerce con nosotros.`
    ]
    },
    {
      id: 3,
      title: 'WhatsApp Business',
      imgUrl: './../../assets/img/card3.png',
      h1: 'WhatsApp Business',
      h2: '¿Quién no ocupa WhatsApp hoy en día?',
      p: [
        `Con el recién lanzado servicio de WhatsApp Business podrás atender y comunicarte con clientes por esta vía. 
        El impacto en su satisfacción será considerable y te diferenciarás de tu competencia. La integración con Whatsapp Business va muy por encima de poner un agente a responder mensajes por WHATSAPP. 
        Whatsapp Business te permite hacer mucho mas, desde llenar formularios,
        hacer compras, reservas, conectarse con tu CRM o sistemas de tickets y mucho mas.`,
        `Además considera cuantos ingresos adicionales y cuanto ahorro generarás en otros canales de atención. Whatsapp Business es una necesidad, no una opción y en GoDigital tenemos los conocimientos y las alianzas que nos permitirán ayudarte para integrar tu empresa a WhatsApp Business.`
      ]
    },
    {
      id: 4,
      title: 'Desarrollos a la medida',
      imgUrl: './../../assets/img/card4.png',
      h1: 'Consultorías y Desarrollos a la medida',
      h2: 'La innovación no es solo acerca de ideas, sino de resolver problemas, necesidades y agregar valor a los negocios a todo nivel',
      p: [
        `Nuestro equipo de consultores y desarrolladores están listos para ayudar a potenciar las oportunidades en tu negocio. Desde temas sencillos como desarrollar un app, una página web o hasta resolver un problema de integración. También podemos ayudarte a buscar herramientas adecuadas para alguna necesidad en particular, que pueden ser desde herramientas ya existentes en el mercado o inclusive algo a desarrollar localmente.
        Estamos para acompañarte en todo este proceso y apoyarte para que tengas éxito en tus ideas, proyectos y necesidades.`,
        `¡Nuestros objetivos, son tus objetivos!`
      ]
    }
  ];
  service: any;
  constructor(
    private _scrollToService: ScrollToService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: any) => {
      this.id = params.params.id;
      console.log(this.id);
      this.filteredServices = this.services.filter((item) => {
        return Number(item.id) !== Number(this.id);
      });
      console.log(this.filteredServices);
      this.service = this.services[this.id - 1];
      const config: ScrollToConfigOptions = {
        target: 'services'
      };
      this._scrollToService.scrollTo(config);
    });
  }

}
