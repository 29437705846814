import { Component, OnInit } from '@angular/core';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(
    private _scrollToService: ScrollToService
  ) { }

  ngOnInit() {
    const config: ScrollToConfigOptions = {
      target: 'about'
    };
    this._scrollToService.scrollTo(config);
  }

}
