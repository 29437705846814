import { Component, OnInit } from '@angular/core';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { NgForm } from '@angular/forms';
import { HttpServiceService } from '../../angularServices/http-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(
    private _scrollToService: ScrollToService,
    private httpService: HttpServiceService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    const config: ScrollToConfigOptions = {
      target: 'contact'
    };
    this._scrollToService.scrollTo(config);
  }
  onSubmit(f: NgForm) {
    console.log(f);
    if (!f.valid) {
      return;
    }
    const body = f.value;
    body.subject = `Enviado desde Web`
    this.httpService.post('send-email', body).subscribe((resp) => {
      console.log(resp);
      this.toastr.success('Mensaje enviado satisfactoriamente', '¡Correcto!');
      f.reset();
    }, (err) => {
      console.error();
      this.toastr.error('Hemos tenido un problema, por favor intenta nuevamente', 'Error!');
    });
  }
}
