import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { NavComponent } from './nav/nav.component';
import { PresentationComponent } from './presentation/presentation.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { GoDigitalRoutingModule } from './godigital-routing.module';
import { FormsModule }   from '@angular/forms';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { GodigitalComponent } from './godigital.component';

@NgModule({
  declarations: [
    FooterComponent,
    HomeComponent,
    ServicesComponent,
    ContactComponent,
    AboutComponent,
    NavComponent,
    PresentationComponent,
    PrivacyPolicyComponent,
    GodigitalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ScrollToModule,
    PdfViewerModule,
    GoDigitalRoutingModule
  ]
})
export class GodigitalModule { }
