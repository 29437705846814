import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrizeComponent } from './prize/prize.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-uno',
  templateUrl: './uno.component.html',
  styleUrls: ['./uno.component.css']
})
export class UnoComponent implements OnInit {

  public unoForm: FormGroup;

  constructor(private modalService: NgbModal,
    private fb: FormBuilder) {

      this.unoForm = this.fb.group({
        fullname: ['', Validators.required],
        email: ['', Validators.required],
        phone: ['', Validators.required],
        type: ['', Validators.required],
        code: ['', Validators.required]
      });

     }

  ngOnInit() {
  }

  onSubmit(values) {

    if (this.unoForm.invalid) {
      console.log("Rellena los campos");
      return;
    }

    const modal = this.modalService.open(PrizeComponent);
    modal.componentInstance.data = values;
    modal.result.then(
      (r: any) => {
        console.log("responese",r);
        if (r) {
          this.unoForm.reset();
        }
      },
      (e: any) => {
        console.log("error", e);
      }
    );
  }

}
